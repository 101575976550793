export default function (Alpine) {
    Alpine.directive('containerimages', (element, { expression, modifiers }, { evaluate, cleanup }) => {
        const originalSrc = element.src;
        let containerWidth;
        let useParent = modifiers.length ? modifiers[0] === 'parent' : false;
        let container = element;
        const srcSets = evaluate(expression);

        if (useParent) {
            container = element.parentElement;
        }

        containerWidth = container.clientWidth;
        const resizeHandler = (event) => {
            containerWidth = container.clientWidth;
            setSrc();
        };

        window.addEventListener('resize', resizeHandler);

        const getSrc = () =>
            Object.entries(srcSets)
                .map(([key, value]) => [Number(key), value])
                .sort(([a], [b]) => a - b)
                .find(([k], i, a) =>
                        k >= containerWidth
                    // k <= containerWidth && (a[i + 1]?.[0] > containerWidth || i === a.length - 1)
                )?.[1];

        const setSrc = () => {
            let src = getSrc();

            if (src) {
                element.src = getSrc();
            } else {
                element.src = originalSrc;
            }
        };

        setSrc();

        cleanup(() => {
            window.removeEventListener('resize', resizeHandler);
        });
    })
}
