import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import tooltip from "@ryangjchandler/alpine-tooltip";
import focus from '@alpinejs/focus';
import containerimages from './containerimages.js';

Alpine.plugin(intersect);
Alpine.plugin(tooltip);
Alpine.plugin(focus);
Alpine.plugin(containerimages);

window.Alpine = Alpine
Alpine.start();


import './smoothscroll';

document.addEventListener('DOMContentLoaded', function() {



});
